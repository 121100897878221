import styled from 'styled-components';
import bg from '~/assets/images/page-bg-bottom.png';

export const Container = styled.div`
  background-image: url(${bg});
  background-position-y: bottom;
  background-size: cover;
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 120px 0;
  text-align: center;
  h1 {
    font-weight: 800;
    margin-bottom: 16px;
    line-height: 1;
  }
  .text-60 {
    font-size: 60px;
  }
  .subheading {
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .text-36 {
    font-size: 36px;
  }
  .text-18 {
    font-size: 18px;
  }

  .text-muted {
    color: #70657b !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
`;
