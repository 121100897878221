import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// import AuthLayout from '~/pages/_layouts/Auth';
// import DefaultLayout from '~/pages/_layouts/Default';
import BlankLayout from '~/pages/_layouts/Blank';

// import { useSelector } from 'react-redux';
export default function RouteWrapper({
  component: Component,
  isPrivate,
  template,
  location,
  ...rest
}) {
  // const signed = localStorage.getItem('token') || false;

  // if (!signed && location.pathname === '/') {
  //   return <Redirect to="/login" />;
  // }
  //
  // if (!signed && isPrivate) {
  //   return <Redirect to="/login" />;
  // }
  //
  // if (signed && !isPrivate) {
  //   return <Redirect to="/profile" />;
  // }

  // const Layout = signed
  //   ? DefaultLayout
  //   : !signed && isPrivate && template === 'auth'
  //   ? AuthLayout
  //   : BlankLayout;

  const Layout = BlankLayout;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

RouteWrapper.defaultProps = {
  location: {
    pathname: '',
  },
};
