export function signInRequest(data) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: data,
  };
}

export function signInSuccess(token, user, organization) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, organization },
  };
}

export function signInFail() {
  return {
    type: '@auth/SIGN_IN_FAIL',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signUpSuccess() {
  return {
    type: '@auth/SIGN_UP_SUCESS',
  };
}

export function signUpFail() {
  return {
    type: '@auth/SIGN_UP_FAIL',
  };
}

export function requestAuthGoogle() {
  return {
    type: '@auth/AUTH_GOOGLE_REQUEST',
  };
}
