import styled, { css } from 'styled-components';
import { device } from '~/styles/device';
import { Modal } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const form_control = css`
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  color: #363636;
  border: 1px solid #dbdbdb;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;

  &:hover {
    border-color: #b5b5b5;
  }
  &:focus {
    border-color: #663399;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
    outline: none;
  }
`;

const Resets = css`
  .react-calendar {
    width: 100%;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e2e8f0;
  }

  .react-calendar__navigation__prev-button[disabled],
  .react-calendar__navigation__next-button[disabled] {
    display: none;
  }

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__navigation button[disabled] {
    background-color: #fff;
  }

  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__tile--active {
    background: none;
    abbr {
      color: #fff !important;
      background: #663399;
    }
  }

  .react-calendar__tile {
    abbr {
      color: #663399;
      font-weight: bold;
      width: 30px;
      padding: 7px;
      height: 30px;
      margin: auto;
      display: block;
      border-radius: 50%;
    }
  }

  .react-calendar__tile:disabled {
    background-color: #fff;
    abbr {
      color: #cecece;
      font-weight: bold;
    }
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    abbr {
      color: #fff;
      background: #663399;
    }
    background: none !important;
  }

  .react-calendar__tile:enabled:hover > abbr:after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #663399;
  }

  .header-desktop {
    display: none;
  }
  @media (min-width: 992px) {
    .header-desktop {
      display: block;
    }
    .header-mobile {
      display: none;
    }
  }
`;

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

 

  p {
    margin-bottom: 0;
  }

  @media ${device.md} {
    //height: 100vh;
  }

  ${Resets}
`;

export const Box = styled.div`
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  height: 100%;

`;

export const Columns = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  flex-direction: column;
  padding-bottom: 40px;
  //min-height: 100vh;

  //background-color: yellow ;

  @media ${device.md} {
    flex-direction: row;
    padding: 0;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;
  background: #663399;
  //background-color: red;
  color: #fff;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  //overflow: auto ;

  @media ${device.md} {
    width: 40%;
    padding: 0 15px;
    text-align: left;
    margin: 0;
    padding: 5rem 3rem 6rem 3rem;
  }
`;

export const Header = styled.header`
  figure {
    display: block;
    position: relative;
    height: 64px;
    width: 64px;
    margin: auto;
  }

  img {
    border-radius: 50%;
    display: block;
    height: 100%;
    width: 100%;
    border: 3px solid #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }

  h1 {
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    color: #fff;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    word-break: break-word;
    margin-top: 0.5rem;
  }

  @media ${device.md} {
    margin-top: 1.5rem;

    figure {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const Line = styled.div`
  border: 1px solid #e1eef4;
  border-width: 0.5px;
  display: inherit;
  width: 174px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  @media ${device.md} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Country = styled.section`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  span {
    &:nth-child(1) {
      position: relative;
      margin-right: 0.5rem;
    }
    &:nth-child(2) {
      font-size: 0.9rem;
    }
  }
`;

export const Description = styled.div`
  margin-bottom: 0.75rem;

  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #fff;
    max-height: 200px;
    overflow-y: auto;
    white-space: pre-line;
    &::-webkit-scrollbar {
      height: 10px;
      width: 6px;
      border-radius: 4px;
      background: transparent;
      transition: all 0.3s ease;
    }
    &::-webkit-scrollbar:hover {
      background: #c6c7ca;
    }
    &::-webkit-scrollbar-thumb {
      background: #a0a0a02b;
      border-radius: 4px;
    }
  }
`;

export const Rate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  > span {
    font-size: 16px;
    display: none;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    span:nth-child(1) {
      font-weight: 700;
      font-size: 1.5rem;
    }
    span:nth-child(2) {
      position: relative;
      top: -2px;
      font-size: 1.25rem;
      margin: 0 5px;
    }

    span {
      img {
        width: 16px;
      }
    }
  }

  @media ${device.md} {
    > span {
      display: block;
    }
    p {
      justify-content: start;
    }
  }
`;

export const ColumnRight = styled.div`
  width: 100%;

  @media ${device.maxMD} {
    max-width: 450px;
    padding: 0 15px;
  }

  @media ${device.md} {
    //padding: 20px 20px 100px 20px;
    margin: 0 auto;
    width: 50%;
    display: block;
  }
`;

export const WrapperCalendar = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.md} {
    flex-direction: row;
  }
`;

export const CalendarContent = styled.div`
  display: flex;
  flex-direction: column;

  .react-calendar {
    border: 0;
  }
`;

export const DateTime = styled.div`
  display: block;
  flex-shrink: 1;
  padding: 0.75rem 0;
  position: relative;

  h1 {
    padding-bottom: 14px;
    border-bottom: 1px solid #e1eef4;
    font-weight: 400;
    font-size: 16px;
  }
`;

export const Timezone = styled.div`
  display: flex;
  margin-top: 14px;
  padding-top: 14px;
  border-top: 1px solid #e1eef4;
  align-items: center;
  color: #663399;
  svg {
    margin-right: 10px;
    width: 16px;
  }
`;

export const TimeWrapper = styled.div`
  padding: 0.75rem 0;
  width: 100%;
  flex-basis: 190px;
  flex-grow: 0;
  flex-shrink: 0;
  transition: 0.3s;
  ${(props) =>
    !props.open
      ? `
    opacity: 0;
    transform: translateX(-30px);
    `
      : `
    opacity: 1;
    transform: translateX(0);
    `}

@media ${device.md} {
    margin-left: 1.5rem;
    max-width: 190px;
  }
`;

export const TimeTitle = styled.h1`
  border-bottom: 1px solid #e1eef4;
  padding-bottom: 14px;
  font-size: 16px;
  font-weight: 400;
`;

export const TimePickerWrapper = styled.div`
  max-height: 410px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 10px;
    width: 6px;
    border-radius: 4px;
    background: transparent;
    transition: all 0.3s ease;
  }
  &::-webkit-scrollbar:hover {
    background: #c6c7ca;
  }
  &::-webkit-scrollbar-thumb {
    background: #a0a0a02b;
    border-radius: 4px;
  }
`;

export const ButtonTimeGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 5px;
  &:first-child {
    margin-top: 0;
  }
`;

export const ButtonTime = styled.button.attrs({
  type: 'button',
})`
  padding: 10px 16px;
  background-color: #f8fcff;
  color: #03449e;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border-radius: 5px;
  line-height: 21px;
  border: 1px solid #bae3ff;
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  position: relative;
  border: 1px solid #03449e;
  border-radius: 5px;
  width: 100%;
`;

export const ButtonConfirm = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  padding: 10px;
  background-color: #663399;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  line-height: 21px;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: #663399;
  }
`;

export const Close = styled.span`
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #03449e;
  position: absolute;
  top: 9px;
  right: 3px;
  line-height: 0;
`;

export const Payment = styled.div`
  display: flex;
  flex-basis: 684px;
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;

  @media ${device.maxMD} {
    margin-top: 10px;
    flex-direction: column;
  }
`;

export const Back = styled.div`
  margin-right: 2rem;
`;

export const ButtonBack = styled.a``;

export const WrapperPayment = styled.div`
  width: 100%;
  @media ${device.md} {
    padding-right: 40px;
  }
`;

export const PaymentTitle = styled.h1`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  display: block;
`;

export const Input = styled(InputMask)`
  ${form_control};
`;

export const Textarea = styled.textarea`
  ${form_control};
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: vertical;

  &:not([rows]) {
    max-height: 40em;
    min-height: 8em;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const FormInline = styled.div`
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: 1rem;

  > div {
    padding: 0.75rem;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export const ControlButtonPayment = styled.div`
  clear: both;
  text-align: right;

  button {
    width: auto;
    padding: 0.75rem 3rem;
    border-radius: 5px !important;
    font-size: 16px;
    margin-top: 20px;
    min-width: 235px;
  }
`;

export const Terms = styled.div`
  input {
    display: none;
    &:checked + label:after {
      content: '';
      position: absolute;
      width: 0.375rem;
      height: 0.6rem;
      top: 0.405rem;
      left: 0.6rem;
      transform: translateY(0) rotate(45deg);
      border-width: 0.1rem;
      border-style: solid;
      border-color: #663399;
      border-top: 0;
      border-left: 0;
      display: inline-block;
    }
  }

  label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: 0.5em;
    padding: 0.2rem 0.5rem 0.2rem 0;
    border-radius: 4px;
    font-size: 1rem;
    padding-left: 2rem;
    margin-left: 0;
    &:before {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      border: 0.1rem solid #dbdbdb;
    }
  }

  a {
    color: #663399;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const SectionLine = styled.div`
  border: 1px solid #e1eef4;
  display: inherit;
  width: 174px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const TimePeriod = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    span {
      background: #d4e8ff;
      border-radius: 100px;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1rem;
    }

    p {
      svg {
        width: 7px;
        margin: 0px 5px -2px 5px;
      }
    }
  }
`;

export const FormPayment = styled.form``;

export const ButtonPlayVideo = styled.button`
  border: 0;
  text-align: left;
  padding: 0;
  background: none;
  font-size: 5rem;
  cursor: pointer;
  line-height: 0;
  &:focus {
    outline: none;
  }
`;

export const Avatar = styled.div`
  position: relative;
  width: 200px;
  min-height: 200px;
  max-height: 200px;

  a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
  }
  svg {
    fill: none;
    stroke: #8a3ab9;
    stroke-linecap: round;
    stroke-width: 3;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 6s ease-out infinite alternate;
    position: absolute;
    top: -25px;
    left: -25px;
    width: 250px;
    height: 250px;
    /* @media ${device.maxLG} {
      left: -25px;
    } */
  }

  img {
    cursor: pointer;
    z-index: 10;
    padding: 5px;
    height: 200px;
    width: 200px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 50%;
    @media ${device.maxLG} {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-20px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }

  @keyframes stroke-draw {
    from {
      stroke: #8a3ab9;
      stroke-dasharray: 1;
    }
    to {
      stroke: #cd486b;
      transform: rotate(180deg);
      stroke-dasharray: 8;
    }
  }
`;

export const ModalVideo = styled(Modal)`
  .modal-content {
    background: none;
    align-items: center;
    max-width: 432px !important;

    button {
      position: absolute;
      top: 10px;
      right: -5px;
      width: 50px;
      height: 50px;
      font-size: 2.5rem;
      color: #ccc;
      line-height: 20px;
      text-align: center;
      background: none;
      border: 0;
      z-index: 99999;
      border-radius: 50%;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
`;

export const ContentLeft = styled.div`
  text-align: left;
  @media ${device.maxLG} {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #663399;
    border: 1px solid #663399;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    &:hover {
      background: #664499;
    }
  }
`;
