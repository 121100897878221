import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '~/services/api';
import ErrorMessage from '~/components/ErrorMessage';

function searchPapo() {
  const { custom_slug } = useParams();
  const history = useHistory();
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await api.get(`/papos/user/${custom_slug}`);
        console.log(response.data);

        if(response.data.length > 0){
          history.push(`/${custom_slug}/${response.data[0].id}`)
        }else{
          setError('Este usuário não tem nenhum papo disponível')
        }

      } catch (err) {
        setError(err.response.data.description)
        console.log(err);
      }
    }
    fetchAPI();
  }, []);

  return (
      <>
        {!error ?
          <h1>Aguarde...</h1>
        :
          <ErrorMessage title={error}/>
        }
      </>
    )

}


export default searchPapo;
