export function getDateTimeRequest(data) {
  return {
    type: '@users/GET_DATETIME_REQUEST',
    payload: { data },
  };
}

export function getDateTimeSuccess(data) {
  return {
    type: '@users/GET_DATETIME_SUCCESS',
    payload: { data },
  };
}

export function getDateTimeError() {
  return {
    type: '@users/GET_DATETIME_ERROR',
  };
}

export function sendPaymentRequest(data) {
  return {
    type: '@users/SEND_PAYMENT_REQUEST',
    payload: data,
  };
}

export function sendPaymentSuccess() {
  return {
    type: '@users/SEND_PAYMENT_SUCCESS',
  };
}

export function sendPaymentError() {
  return {
    type: '@users/SEND_PAYMENT_ERROR',
  };
}

export function validateCupomRequest(data) {
  return {
    type: '@users/VALIDATE_CUPOM_REQUEST',
    payload: data,
  };
}

export function validateCupomError(error) {
  return {
    type: '@users/VALIDATE_CUPOM_ERROR',
    payload: { error },
  };
}

export function finishPaymentRequest() {
  return {
    type: '@users/FINISHED_PAYMENT_REQUEST',
  };
}

export function finishPaymentSuccess() {
  return {
    type: '@users/FINISHED_PAYMENT_SUCCESS',
  };
}
