import styled from 'styled-components';
import { device } from '~/styles/device';

export const Container = styled.div`
  @media ${device.maxLG} {
    margin-top: 40px;
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  text-align: left;
  color: rgb(0, 0, 0);
  line-height: 40px;
  margin: auto;
`;

export const Description = styled.div`
  margin-top: 28px;
  margin-bottom: 52px;
  line-height: 1.5em;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  color: rgb(57, 79, 90);
`;

export const Card = styled.div`
  font-size: 14px;
  line-height: 2em;
  color: #394f5a;
  text-align: left;
  margin: auto auto 8px;
  max-width: 530px;
  width: 100%;
  padding: 15px 30px;
  align-items: center;
  border: 1px solid #e1eef4;
  background-color: #fff;
  margin: 1rem auto;
  @media ${device.lg} {
    font-size: 16px;
  }
  & ${Description} {
    margin-bottom: 0;
  }
`;

export const MeetingCard = styled.div`
  font-size: 14px;
  line-height: 2em;
  color: #394f5a;
  text-align: left;
  margin: auto auto 8px;
  max-width: 530px;
  margin: auto;
  width: 100%;
  @media ${device.lg} {
    font-size: 18px;
  }

  .meeting-card-top-border {
    background-color: #663399;
    border-color: #663399;
    width: 100%;
    height: 5px;
    border: 1px solid;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

export const MeetingCardBody = styled.div`
  display: flex;
  padding: 15px 30px;
  width: 100%;
  align-items: center;
  border: 1px solid #e1eef4;
  background-color: #fff;
`;

export const MeetingCardIcon = styled.div`
  margin-right: 40px;
  background: #d4e8ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  svg {
    font-size: 3em;
    width: 70px;
    height: 70px;
  }
`;

export const MeetingCardDetails = styled.div`
  flex: auto;
`;

export const MeetingCardTimes = styled.div``;

export const Help = styled.div`
  max-width: 530px;
  margin: auto;
  width: 100%;
  margin-top: 40px;
  a {
    border: 1px solid #663399;
    background-color: #fff;
    padding: 15px 0;
    border-radius: 30px;
    text-align: center;
    font-size: 1.2em;
    display: block;
    text-decoration: none;
    color: #394f5a;
    strong {
      font-weight: bold;
      color: #1a1a1a;
    }
  }
`;
