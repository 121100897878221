import { all, call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  sendPaymentSuccess,
  sendPaymentError,
  validateCupomError,
  finishPaymentSuccess,
} from './actions';

export function* sendPayment({ payload }) {
  const {
    cupom,
    nome,
    date,
    from,
    to,
    email,
    user_id,
    papo_id,
    date_time,
    custom_field,
    custom_field_value,
    telephone = '',
  } = payload;

  console.log(
    cupom,
    nome,
    date,
    from,
    to,
    email,
    user_id,
    papo_id,
    date_time,
    custom_field,
    custom_field_value
  );

  try {
    const context = {
      start: {
        dateTime: date_time,
        timeZone: 'America/Sao_Paulo',
      },
      attendees: [
        {
          email,
        },
      ],
      sendUpdates: all,
      description: `Telefone: ${telephone}, ${
        custom_field_value
          ? `Campo extra: ${custom_field}, resposta: ${custom_field_value}`
          : ''
      }`,
      summary: 'papo vip com: ' + email,
      conferenceData: {
        createRequest: {
          conferenceSolutionkey: {
            type: 'eventHangout',
          },
          requestId: '123abc',
        },
      },
      reminders: {
        useDefault: false,
        overrides: [
          { method: 'email', minutes: 16 * 60 },
          { method: 'popup', minutes: 120 },
          { method: 'email', minutes: 30 },
          { method: 'popup', minutes: 20 },
        ],
      },
    };

    context.attendee_data = {
      name: nome,
      phone: telephone,
      custom_field: custom_field,
      custom_field_value: custom_field_value,
      email: email,
    };

    console.log('context:', context);
    console.log('context Data', context.attendee_data);

    const response = yield call(
      api.post,
      `/users/${user_id}/${papo_id}`,
      context
    );
    //toast.success("response_payment.data.description");
    // yield put(sendPaymentSuccess());
    // yield put(finishPaymentSuccess());
    // history.push({
    //   pathname: '/a/g',
    //   state: {
    //     email,
    //     to,
    //     from,
    //     date,
    //   },
    // });
    const { id, owner_id } = response.data;

    console.log('resposta', response);
    yield put(sendPaymentSuccess());

    if (response.data.error && response.data.description != '') {
      toast.error(response.data.description);
      yield put(sendPaymentError());
    }
    if (id) {
      const response_payment = yield call(
        api.get,
        `/payments/${owner_id}/${email}/${id}/${papo_id}${
          cupom ? '?coupon=' + cupom : ''
        }`
      );
      const { redirect_url } = response_payment.data;
      toast.success(response_payment.data.description);

      yield put(sendPaymentSuccess());
      yield put(finishPaymentSuccess());
      if (redirect_url) {
        window.location.href = redirect_url;
      } else {
        history.push({
          pathname: '/a/g',
          state: {
            email,
            to,
            from,
            date,
          },
        });
      }
    }
  } catch (err) {
    toast.error('Erro ao agendar horário');
    yield put(sendPaymentError());
  }
}

export function* validateCupom({ payload }) {
  try {
    const { user_id, cupom } = payload;
    const response = yield call(api.get, `/coupons/${user_id}/${cupom}`);
    if (!response.data.is_valid) {
      toast.error('Cupom expirado');
    }
  } catch (err) {
    //toast.error('Cupom não encontrado');
    console.dir(err);
    yield put(validateCupomError(err.response.data.description));
    console.log('err', err);
  }
}

export default all([
  takeLatest('@users/SEND_PAYMENT_REQUEST', sendPayment),
  takeLatest('@users/VALIDATE_CUPOM_REQUEST', validateCupom),
]);
