import React from 'react';

import { Container } from './styles';

export default function ErrorMessage({ title }) {
  return (
    <Container className="not-found-wrap text-center">
      <h1 className="text-60">404</h1>
      <p className="text-36 subheading mb-3">Error!</p>
      <p className="mb-5  text-muted text-18">{title}</p>
      <a
        href="https://app.papovip.com.br/minhaconta"
        className="btn btn-lg btn-primary btn-rounded"
      >
        Clique aqui
      </a>
    </Container>
  );
}
