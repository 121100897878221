import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import history from '~/services/history';

import {
  signInSuccess,
  signInFail,
  signUpSuccess,
  signUpFail,
} from './actions';

export function* signIn({ payload }) {
  console.log('payload', payload);
  try {
    const { username, password } = payload;

    const response = yield call(api.post, '/login', {
      username,
      password,
    });

    const { token, name } = response.data;
    yield put(signInSuccess(token, name));
    history.push('/profile');
  } catch (err) {
    yield put(signInFail());
    toast.error('Sign in fail, check your email and password');
  }
}

export function signOut() {
  delete api.defaults.headers.Authorization;
  history.push('/login');
}

export function* signUp({ payload }) {
  try {
    const {
      firstName: first_name,
      lastName: last_name,
      organization,
      email,
      password,
      passwordConfirmation: password_confirmation,
    } = payload;

    yield call(api.post, '/register', {
      first_name,
      last_name,
      organization,
      email,
      password,
      password_confirmation,
    });
    yield put(signUpSuccess());
  } catch (err) {
    yield put(signUpFail());
    toast.error('Error. E-mail already exists!');
  }
}
export function setTokenSignIn({ payload }) {
  if (!payload) return;

  const { token } = payload;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.Accept = 'application/json';
    api.defaults.headers['Content-Type'] = 'application/json';
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.Accept = 'application/json';
    api.defaults.headers['Content-Type'] = 'application/json';
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenSignIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
