const size = {
  xs: '576px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1366px',
  xxl: '1600px',
  xxxl: '1900px',

  maxXS: '575px',
  maxSM: '767px',
  maxMD: '991px',
  maxLG: '1199px',
  maxXL: '1599px',
};

export const device = {
  xs: `(min-width:${size.xs})`,
  sm: `(min-width:${size.sm})`,
  md: `(min-width:${size.md})`,
  lg: `(min-width:${size.lg})`,
  xl: `(min-width:${size.xl})`,
  xxl: `(min-width:${size.xxl})`,
  xxxl: `(min-width:${size.xxxl})`,

  maxXS: `(max-width: ${size.maxXS})`,
  maxSM: `(max-width: ${size.maxSM})`,
  maxMD: `(max-width: ${size.maxMD})`,
  maxLG: `(max-width: ${size.maxLG})`,
  maxXL: `(max-width: ${size.maxXL})`,
};
