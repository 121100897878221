import React from 'react';
import { useLocation } from 'react-router-dom';
import history from '~/services/history';
import moment from 'moment';

import {
  Container,
  Title,
  Description,
  MeetingCard,
  MeetingCardBody,
  MeetingCardIcon,
  MeetingCardDetails,
  MeetingCardTimes,
  Card,
  Help,
} from './styles';

function Agradecimentos({
  history: {
    location: { state },
  },
}) {
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function getEmail() {
    return (state && state.email) || query.get('email');
  }

  function getFromDate() {
    return state && state.from
      ? state.from
      : moment(query.get('start')).format('HH:mm');
  }

  function getToDate() {
    return state && state.to
      ? state.to
      : moment(query.get('end')).format('HH:mm');
  }

  function getDate() {
    return state && state.date
      ? state.date
      : moment(query.get('start')).format('LL');
  }

  return (
    <>
      <Container>
        <Card>
          <Title>Parabéns! Seu Papo Vip foi agendado com sucesso!</Title>
          <Description>
            Verifique seu endereço de e-mail:
            {getEmail()}
          </Description>
        </Card>
        <MeetingCard>
          <div className="meeting-card-top-border"></div>
          <MeetingCardBody>
            <MeetingCardIcon>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z"
                  stroke="#0967D2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M10.667 1.33337V4.00004"
                  stroke="#0967D2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M5.33301 1.33337V4.00004"
                  stroke="#0967D2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M2 6.66663H14"
                  stroke="#0967D2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </MeetingCardIcon>
            <MeetingCardDetails>
              <MeetingCardTimes>
                {getFromDate()} — {getToDate()}
              </MeetingCardTimes>
              <MeetingCardTimes>{getDate()}</MeetingCardTimes>
              <MeetingCardTimes>
                Fuso horário atual (America/Sao_Paulo)
              </MeetingCardTimes>
            </MeetingCardDetails>
          </MeetingCardBody>
        </MeetingCard>

        <Help>
          <a href="https://papovip.com.br">
            {' '}
            Quer ter um calendário desse? <strong>Clique Aqui</strong>
          </a>
        </Help>
      </Container>
    </>
  );
}

export default Agradecimentos;
