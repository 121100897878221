import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';

const Redirect = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory()

    useEffect(() => {
        async function execute() {
            const response = await fetch("https://api-papo-vip.herokuapp.com/integrations/urlpapo", 
            {
                method: "POST",
                body: JSON.stringify({
                    "userToken": urlParams.get("usertoken"),
                    "platform": urlParams.get("platform")
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const response2 = await response.json()
            console.log("Ramerson aqui =>", response2.data)

            history.push(`/${response2.data}`)
        }
        
        execute()
    }, [])



    return (
        <div>redirect</div>
    )
}

export default Redirect