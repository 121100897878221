import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Calendario from '~/pages/Calendar';
import Error404 from '~/pages/Error404';
import Agradecimentos from '~/pages/Agradecimentos';
import SearchPapo from '../components/searchPapo';
import RedirectPage from '~/pages/redirect';

export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => (window.location = 'https://www.papovip.com.br')}
      />
      <Route exact path="/a/g" component={Agradecimentos} template="blank" />
      <Route exact path="/redirect" component={RedirectPage} template="blank" />
      <Route path="/:id/:papo" component={Calendario} template="blank" />
      <Route path="/:custom_slug" component={SearchPapo} template="blank" />
      <Route component={Error404} template="blank" />
    </Switch>
  );
}
