import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import languagePTBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { currencyFormat, timeConvert, getArrayHours } from '~/helpers';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import api from '~/services/api';
import avatar from '~/assets/images/avatar.png';
import { FiInfo } from 'react-icons/fi';

import { useSelector, useDispatch } from 'react-redux';
import Loading from '~/components/Loading';
import ErrorMessage from '~/components/ErrorMessage';
import Spinner from '~/components/Spinner';

import Stories from 'react-insta-stories';

import {
  Wrapper,
  Box,
  Columns,
  ColumnLeft,
  ColumnRight,
  Header,
  Line,
  Description,
  Rate,
  WrapperCalendar,
  CalendarContent,
  DateTime,
  Timezone,
  TimeWrapper,
  TimeTitle,
  TimePickerWrapper,
  ButtonTimeGroup,
  ButtonTime,
  ButtonConfirm,
  ButtonGroup,
  Payment,
  Back,
  ButtonBack,
  WrapperPayment,
  PaymentTitle,
  Label,
  Input,
  FormGroup,
  FormPayment,
  ControlButtonPayment,
  Terms,
  TimePeriod,
  Avatar,
  SectionLine,
  ModalVideo,
  ContentLeft,
  InputGroup,
} from './styles';

import {
  sendPaymentRequest,
  finishPaymentRequest,
} from '~/store/modules/users/actions';

export default function Calendario() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [cupom, setCupom] = useState(false);
  const [errorCupom, setErrorCupom] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [getProfileTutor, setProfileTutor] = useState('');
  const [getProfileUser, setProfileUser] = useState({});
  const [getTimePeriod, setTimePeriod] = useState({});
  const [getTimePeriodUser, setTimePeriodUser] = useState({});
  const [getPayment, setPayment] = useState(false);
  const [getLoading, setLoading] = useState(true);
  const [getError, setError] = useState(false);

  const { id, papo } = useParams();
  const loading = useSelector((state) => state.users.loading);

  useEffect(() => {
    dispatch(finishPaymentRequest());
  }, []);

  useEffect(() => {
    function loadProfileTutor() {
      api
        .get(`/users/${id}/${papo}`)
        .then((res) => {
          if (!getProfileTutor) {
            setProfileTutor(res.data);
            setLoading(false);
          }
          setError({ error: false });
        })
        .catch((err) => {
          setError({
            error: true,
            description:
              (err.response && err.response.data.description) ||
              'O conteúdo procurado não está disponível!',
          });
          setLoading(false);
        });
    }

    loadProfileTutor();
  }, []);

  function handleChangeCalendar(date) {
    const newDate = new Date(date);
    let availability_start = getProfileTutor.papo.availability_start;
    let availability_end = getProfileTutor.papo.availability_end;

    availability_start = moment(availability_start, 'HH:mm');
    availability_end = moment(availability_end, 'HH:mm');

    let minimum_time_start_papos =
      getProfileTutor.papo.minimum_time_start_papos;

    // if (moment(newDate).isSame(new Date(), 'day')) {
    //   availability_start
    //     .add(minimum_time_start_papos, 'minutes')
    //     .format('HH:mm');
    // }
    console.log('OBJECT', {
      minimum_time_start_papos: minimum_time_start_papos,
      start: availability_start,
      end: availability_end,
      data: getProfileTutor.data,
      moment: moment(newDate, 'YYYY-MM-DD'),
      interval: getProfileTutor.papo.interval_between_papos,
    });
    if (!openTimePicker) {
      setOpenTimePicker(true);
    }

    let arrayHours = [];
    arrayHours = getArrayHours(
      minimum_time_start_papos,
      availability_start,
      availability_end,
      getProfileTutor.data,
      moment(newDate, 'YYYY-MM-DD'),
      getProfileTutor.papo.interval_between_papos
    );

    console.log('getProfileTutor', getProfileTutor);
    console.log('ARRAY HORAS', arrayHours);

    setProfileTutor((prevState) => ({
      ...prevState,
      schedules: arrayHours,
    }));
    setProfileUser((prevState) => ({
      ...prevState,
      due_date: newDate,
      date: format(newDate, 'ccc, LLL dd', {
        locale: languagePTBR,
      }),
    }));
    setTimePeriod({
      period: format(newDate, 'ccc, LLL dd', {
        locale: languagePTBR,
      }),
    });
  }

  function handleConfirmTime(id) {
    const newArr = getProfileTutor.schedules.map((times) =>
      times.id === id
        ? { ...times, isConfirm: true }
        : { ...times, isConfirm: false }
    );
    setProfileTutor((prevState) => ({
      ...prevState,
      schedules: newArr,
    }));
  }

  function handlePayment(event) {
    var time = moment(event.target.dataset.value, 'HH:mm');
    setPayment(true);
    setErrorCupom(false);
    setTimePeriodUser({
      from: event.target.dataset.value,
      to: time.add(getProfileTutor.papo.time, 'm').format('HH:mm A'),
    });
  }

  const handlePaymentBack = useCallback(
    (event) => {
      setPayment(false);
    },
    [getPayment]
  );

  function disableDays(day) {
    const days = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];

    return !getProfileTutor.papo.available_days[days[day.getDay()]];
  }

  function handlePaymentForm(e) {
    e.preventDefault();
    const date = moment(getProfileUser.due_date, 'YYYY-MM-DD').format(
      'YYYY-MM-DD'
    );
    const time = moment(getTimePeriodUser.from, 'HH:mm:ss').format('HH:mm:ss');

    dispatch(
      sendPaymentRequest({
        from: getTimePeriodUser.from,
        to: getTimePeriodUser.to,
        date: getProfileUser.date,
        user_id: id,
        papo_id: papo,
        cupom,
        date_time: `${date}T${time}`,
        nome: e.target.nome.value,
        email: e.target.email.value,
        custom_field: getProfileTutor.papo.custom_field_value,
        custom_field_value: e.target.custom_field_value
          ? e.target.custom_field_value.value
          : false,
        telephone: e.target.telephone.value,
      })
    );
  }

  function handleCupom(e) {
    setCupom(e.target.value);
  }

  function handleValidateCupom(e) {
    api
      .get(`/coupons/${getProfileTutor.papo.owner_id}/${cupom}`)
      .then((response) => {
        setErrorCupom('Cupom válido!');
      })
      .catch((err) => {
        setErrorCupom(err.response.data.description);
      });
  }

  function handleChange(e) {
    setProfileUser({
      ...getProfileUser,
      email: e.target.value,
    });
  }

  const stories = [
    {
      url: `${
        getProfileTutor.user
          ? `https://embed.ziggeo.com/v1/applications/${
              process.env.REACT_APP_ZIGGEO_APP_TOKEN
            }/videos/${
              getProfileTutor.user.video_token &&
              getProfileTutor.user.enable_video
                ? getProfileTutor.user.video_token
                : '1f4f4449b6a5b1c9a8abdd7e843ef00d'
            }/video.mp4`
          : ''
      }`,
      type: 'video',
      duration: 1500,
    },
  ];

  const storyContent = {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margem: 'auto',
  };

  return (
    <>
      {getLoading ? (
        <Loading />
      ) : getError.error ? (
        <ErrorMessage title={getError.description} />
      ) : (
        <>
          <Wrapper>
            <Box>
              <Columns>
                <>
                  <ColumnLeft>
                    <ContentLeft>
                      {!getProfileTutor.user.enable_video &&
                      getProfileTutor.user.enable_video < 1 ? (
                        <Avatar>
                          <img
                            style={{ cursor: 'auto' }}
                            src={
                              getProfileTutor.user.photo_url
                                ? getProfileTutor.user.photo_url
                                : avatar
                            }
                            onError={(e) => (e.target.src = avatar)}
                            alt="profile"
                          />
                        </Avatar>
                      ) : (
                        <Avatar onClick={() => handleShow()}>
                          <img
                            src={
                              getProfileTutor.user.photo_url
                                ? getProfileTutor.user.photo_url
                                : avatar
                            }
                            onError={(e) => (e.target.src = avatar)}
                            alt="profile"
                          />

                          <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="50" cy="50" r="40" />
                          </svg>
                        </Avatar>
                      )}

                      <Header>
                        <h1>{getProfileTutor.user.name}</h1>
                        <h3>
                          {getProfileTutor.user.title ||
                            'Nenhum título no momento!'}
                        </h3>
                      </Header>

                      <Line />
                      <Description>
                        <p>
                          {getProfileTutor.user.description ||
                            'Nenhuma descrição no momento!'}
                        </p>
                      </Description>

                      <Rate>
                        <span style={{}}>
                          <label>
                            Tempo: {timeConvert(getProfileTutor.papo.time)}{' '}
                          </label>
                        </span>
                        {/* <span>Valor:</span>
                        <p>
                          <span>
                            R{currencyFormat(getProfileTutor.papo.value)}
                          </span>
                          <span>
                            / {timeConvert(getProfileTutor.papo.time)}
                          </span>
                        </p> */}
                      </Rate>

                      {getPayment && (
                        <TimePeriod>
                          <SectionLine />
                          <div>
                            <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M10.667 1.33337V4.00004"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M5.33301 1.33337V4.00004"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M2 6.66663H14"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <p>{getTimePeriod.period}</p>
                          </div>

                          <div>
                            <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M8 4V8L10.6667 9.33333"
                                  stroke="#0967D2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <div>
                              <p>
                                {getTimePeriodUser.from}
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="angle-right"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 256 512"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="currentColor"
                                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                                  ></path>
                                </svg>
                                {getTimePeriodUser.to}
                              </p>
                            </div>
                          </div>
                        </TimePeriod>
                      )}
                    </ContentLeft>
                  </ColumnLeft>
                  <ColumnRight>
                    {!getPayment ? (
                      <WrapperCalendar>
                        <CalendarContent>
                          <DateTime>
                            <h1>Selecione uma data e um horário</h1>
                          </DateTime>
                          <Calendar
                            minDate={new Date()}
                            maxDate={
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() + 2,
                                new Date().getDate()
                              )
                            }
                            next2Label={''}
                            prev2Label={''}
                            minDetail="month"
                            locale={'pt-br'}
                            onChange={handleChangeCalendar}
                            tileDisabled={({ date }) => disableDays(date)}
                          />
                          <Timezone>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                            {`(GMT-03:00) Sao Paulo (${moment(
                              new Date(),
                              'HH:mm'
                            ).format('HH:mm')})`}
                          </Timezone>
                        </CalendarContent>
                        <TimeWrapper open={openTimePicker}>
                          <TimeTitle>{getProfileUser.date || ''}</TimeTitle>
                          <TimePickerWrapper>
                            {getProfileTutor.schedules &&
                              getProfileTutor.schedules.map(
                                ({ id, time, isConfirm }) => (
                                  <ButtonTimeGroup key={id}>
                                    <ButtonTime
                                      onClick={() => handleConfirmTime(id)}
                                    >
                                      {time}
                                    </ButtonTime>

                                    {isConfirm && (
                                      <ButtonGroup>
                                        <ButtonConfirm
                                          onClick={handlePayment}
                                          data-value={time}
                                        >
                                          Agendar
                                        </ButtonConfirm>
                                      </ButtonGroup>
                                    )}
                                  </ButtonTimeGroup>
                                )
                              )}
                          </TimePickerWrapper>
                        </TimeWrapper>
                      </WrapperCalendar>
                    ) : (
                      <Payment>
                        <Back>
                          <ButtonBack onClick={handlePaymentBack}>
                            <svg
                              data-v-5e88c492=""
                              width="36"
                              height="36"
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                data-v-5e88c492=""
                                cx="18"
                                cy="18"
                                r="18"
                                fill="#F0F0F0"
                              ></circle>
                              <path
                                data-v-5e88c492=""
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.414 18l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L16.414 18z"
                                fill="#000"
                              ></path>
                            </svg>
                          </ButtonBack>
                        </Back>

                        <WrapperPayment>
                          <PaymentTitle>Detalhes do Agendamento</PaymentTitle>
                          <FormPayment onSubmit={handlePaymentForm}>
                            <FormGroup>
                              <Label>Nome Completo</Label>
                              <Input type="text" name="nome" required />
                            </FormGroup>

                            <FormGroup>
                              <Label>E-mail</Label>
                              <Input
                                type="email"
                                name="email"
                                required
                                onChange={handleChange}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label>Telefone (Com DDD, somente números)</Label>
                              <Input
                                type="text"
                                name="telephone"
                                mask="(99) 99999-9999"
                                required
                              />
                            </FormGroup>

                            {getProfileTutor.papo.show_custom_field &&
                            getProfileTutor.papo.show_custom_field > 0 ? (
                              <FormGroup>
                                <Label>
                                  {getProfileTutor.papo.custom_field_value}
                                </Label>
                                <Input
                                  type="text"
                                  name="custom_field_value"
                                  required
                                />
                              </FormGroup>
                            ) : (
                              ''
                            )}

                            {getProfileTutor.validCoupons && (
                              <FormGroup>
                                <Label>Cupom de desconto</Label>
                                <InputGroup>
                                  <Input
                                    onChange={handleCupom}
                                    type="text"
                                    style={{ textTransform: 'uppercase' }}
                                    name="cupom"
                                    defaultValue={new URLSearchParams(
                                      window.location.href
                                    ).get('cupom')}
                                  />
                                  <button
                                    type="button"
                                    onClick={handleValidateCupom}
                                  >
                                    Aplicar
                                  </button>
                                </InputGroup>
                                {errorCupom && (
                                  <small style={{ color: '#f00' }}>
                                    {errorCupom}
                                  </small>
                                )}
                              </FormGroup>
                            )}

                            <FormGroup>
                              <Terms>
                                <input
                                  type="checkbox"
                                  name="terms"
                                  required
                                  id="terms"
                                />
                                <label htmlFor="terms">
                                  Eu aceito os termos Papo.vip
                                  <a
                                    href="https://papovip.com.br"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {' '}
                                    Termos de uso
                                  </a>
                                </label>
                              </Terms>
                            </FormGroup>

                            <ControlButtonPayment>
                              <ButtonConfirm type="submit">
                                {loading ? (
                                  <Spinner />
                                ) : getProfileTutor.papo.value ? (
                                  'Agendar e Pagar'
                                ) : (
                                  'Agendar'
                                )}
                              </ButtonConfirm>
                            </ControlButtonPayment>
                          </FormPayment>
                        </WrapperPayment>
                      </Payment>
                    )}
                  </ColumnRight>
                </>
              </Columns>
            </Box>
          </Wrapper>

          <ModalVideo show={show} onHide={handleClose} animation centered>
            <button type="button" onClick={handleClose}>
              &times;
            </button>
            <Stories
              stories={stories}
              storyStyles={storyContent}
              defaultInterval={1500}
              width={432}
              height={768}
              onStoryEnd={handleClose}
            />
          </ModalVideo>
        </>
      )}
    </>
  );
}
