import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  profile: {},
  error: null,
  hours: [],
  finished: false,
};

export default function users(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@users/GET_DATETIME_REQUEST':
        draft.loading = true;
        break;
      case '@users/GET_DATETIME_SUCCESS':
        draft.loading = false;
        draft.hours = action.payload.data;
        break;
      case '@users/GET_DATETIME_ERROR':
        draft.loading = false;
        break;
      case '@users/SEND_PAYMENT_REQUEST':
        draft.loading = true;
        break;
      case '@users/SEND_PAYMENT_SUCCESS':
        draft.loading = false;
        break;
      case '@users/SEND_PAYMENT_ERROR':
        draft.loading = false;
        break;
      case '@users/VALIDATE_CUPOM_ERROR':
        draft.error = action.payload.error;
        draft.loading = false;
        break;
      case '@users/FINISHED_PAYMENT_REQUEST':
        draft.finished = false;
        break;
      case '@users/FINISHED_PAYMENT_SUCCESS':
        draft.finished = true;
        break;
      default:
        return state;
    }
  });
}
