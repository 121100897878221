import moment from 'moment';

export function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function timeConvert(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return n > 59
    ? rhours > 1
      ? rhours + ' hrs'
      : rhours + ' hr'
    : rminutes + ' min';
}

export function convertTime(hms) {
  if (hms.length < 3) {
    return hms;
  } else if (hms.length < 6) {
    const a = hms.split(':');
    return (hms = +a[0] * 60 * 60 + a[1] * 60);
  } else {
    const a = hms.split(':');
    return (hms = +a[0] * 60 * 60 + a[1] * 60 + +a[2]);
  }
}

export function difference(a, b) {
  return Math.abs(a - b);
}

export function arrayBetweenNumbers(numberLeft, numberRight, limit) {
  const to = convertTime(numberLeft) / 3600;
  const from = convertTime(numberRight) / 3600;
  let newArray = [];
  var diff = difference(from, to);
  for (var i = diff; i <= limit; i++) {
    newArray.push(i);
  }
  return newArray;
}

const isTimeAvailable = (day, hours, minimum_time_start_papos) => {
  const now = moment(new Date(), 'YYYY-MM-DD HH:mm');
  const targetTime = moment(day);
  let [hour, minutes] = hours.split(':');
  targetTime.hour(hour).minute(minutes);

  return targetTime.isSameOrAfter(now.add(minimum_time_start_papos, 'minutes'));
};

const generateHours = (start, end, intervalMinutes) => {
  const startTime = start;
  const endTime = end;
  const hours = [];

  // console.log('START FUNCT', startTime);
  // console.log('endTime FUNCT', endTime);
  do {
    console.log('startTime format', startTime.format('HH:mm'));
    hours.push(startTime.format('HH:mm'));
    startTime.add(intervalMinutes, 'minutes');
  } while (startTime.isSameOrBefore(endTime));

  return hours;
};

export function getArrayHours(
  minimum_time_start_papos,
  availability_start,
  availability_end,
  data,
  day,
  interval_between_papos
) {
  let arrayObj = [];
  // availability_start = moment(availability_start, 'HH:mm');
  // availability_start = availability_start.minute()
  //   ? availability_start.add(1, 'hour').startOf('hour')
  //   : availability_start.startOf('hour');
  // availability_end = moment(availability_end, 'HH:mm');
  // day = moment(day);
  console.log('day1', day);
  console.log('availability_start func', availability_start);
  console.log('availability_end func', availability_end);
  let array_hours = [];

  // for (
  //   let i = availability_start;
  //   i.isSameOrBefore(availability_end);
  //   i.add(time, 'minutes').format('HH:mm')
  // ) {
  //   array_hours.push(i.format('HH:mm'));
  //   i.add(interval_between_papos, 'minutes').format('HH:mm');
  // }
  const availableHours = generateHours(
    availability_start,
    availability_end,
    interval_between_papos
  );
  console.log('availableHours', availableHours);
  array_hours = availableHours.filter((hour) =>
    isTimeAvailable(day, hour, minimum_time_start_papos)
  );
  console.log('array_hours', array_hours);
  console.log('array push func', array_hours);
  array_hours.map((value, index) => {
    for (let i = 0; i < data.length; i++) {
      let busy_day = moment(data[i].start.split('T')[0], 'YYYY-MM-DD');
      console.log('busy_day 1', busy_day.toISOString());
      console.log('day 2', moment(day, 'YYYY-MM-DD').toISOString());

      const isSameYear = busy_day.isSame(day, 'year');
      const isSameMonth = busy_day.isSame(day, 'month');
      const isSameDayOfMonth = busy_day.isSame(day, 'date');
      if (isSameYear && isSameMonth && isSameDayOfMonth) {
        let start = moment(data[i].start.split('T')[1].split('-')[0], 'HH:mm');
        let end = moment(data[i].end.split('T')[1].split('-')[0], 'HH:mm');
        console.log('start for', start);
        console.log('end', end);
        if (moment(value, 'HH:mm').isBetween(start, end, null, '[)')) {
          return;
        }
      }
    }
    arrayObj.push({
      id: index,
      time: moment(value, 'h:mm A').format('HH:mm A'),
    });
    return value;
  });
  console.log('return arrayObj', arrayObj);
  return arrayObj;
}
