import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  body{
    overflow: auto;
  }
`;

export default GlobalStyle;
